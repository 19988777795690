import APIService from "../APIService";

const API = process.env.REACT_APP_VENDOR_API;

const vendorService = {
  getVendor() {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendors/getVendor`,
          method: "GET",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  fetchVendorAttachments(status) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorDetail/vendorId?status=${status}&page=1&size=100`,
          method: "GET",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  updateVendorData(options = {}) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendors/details`,
          method: "PUT",
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  updateVendorAttachmentStatus(options = {}) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorDetail/editIncomplete`,
          method: "PUT",
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  checkTeamStatus(teamId) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorEdit/teamCheck?teamId=${teamId}`,
          method: "GET",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  deleteTeam(data) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorEdit/remove-teamData`,
          method: "POST",
          data,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  deleteAttachment({ URL, fileType }) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorDetail/file?URL=${URL}&fileType=${fileType}`,
          method: "DELETE",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  updateVendorDetails(options = {}) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorEdit/details`,
          method: "POST",
          data: options,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  fetchProfileEditDetails() {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorEdit/status-failed`,
          method: "GET",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  markProfileEditStatusAsRead(data) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorEdit/status`,
          method: "POST",
          data,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  fetchVendorAttachmentsByCategory(fileType) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/vendorDetail/vendor?fileType=${fileType}`,
          method: "GET",
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};

export default vendorService;
