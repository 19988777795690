import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import RouteConstants from "../constants/RouteConstants";
import get from "lodash/get";
import AuthService from "../services/AuthService";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../routes/paths";
// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, vendor } = useAuth();

  const vendorStatus = get(vendor, "status");

  if (isAuthenticated && vendorStatus === "Partially Completed") {
    return (
      <Navigate
        to={`/${RouteConstants.ROOT_AUTH}/${RouteConstants.EDIT}`}
        replace={true}
        state={{ editProfile: false }}
      />
    );
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={`/${RouteConstants.ROOT_APP}/${RouteConstants.DASHBOARD}`}
      />
    );
  }

  return <>{children}</>;
}
