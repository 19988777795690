import { format, getTime, formatDistanceToNow } from "date-fns";
import moment from "moment";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "yyyy-MM-dd");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export function formatDate(date) {
  return format(new Date(date), "dd-mm-yyyy");
}
export function UTC_TO_DD__MM_YYYY(date) {
  return moment.utc(date).format("DD-MM-YYYY");
}
export function Format__YYYY_MM_DD(date) {
  return moment(date).format("YYYY-MM-DD");
}
