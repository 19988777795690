import React, { useState } from "react";
import PropTypes from "prop-types";
import { m, AnimatePresence } from "framer-motion";
import { alpha } from "@mui/material/styles";
import { List, IconButton, ListItemText, ListItem } from "@mui/material";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { toast } from "react-toastify";

import { fData } from "../../utils/formatNumber";
import getFileData from "../../utils/getFileData";
import Image from "../Image";
import Iconify from "../Iconify";
import { varFade } from "../animate";
import PDFpreview from "../../sections/auth/register/PDFpreview";
import VendorService from "../../services/API/VendorService";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import MessageConstants from "../../constants/MessageConstants";

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  showPreview: PropTypes.bool,
};

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
  type,
}) {
  const [file, setFile] = useState({});
  const [isDeleteConfirmtionPopup, setIsDeleteConfirmationPopup] =
    useState(false);

  const hasFile = files.length > 0;

  function handleOnDelete(file) {
    if (get(file, "isPreviousAttachment")) {
      setIsDeleteConfirmationPopup(true);
      setFile(file);
      return;
    }
    onRemove(file);
  }

  function handleCancelDelete() {
    setFile({});
    setIsDeleteConfirmationPopup(false);
  }

  async function handleConfirmDelete() {
    try {
      await VendorService.deleteAttachment({
        URL: get(file, "URL"),
        fileType: get(file, "fileType"),
      });
      onRemove(file);
      toast.success(
        `${startCase(type)} ${MessageConstants.DELETED_SUCCESSFULLY}`
      );
      setFile({});
      setIsDeleteConfirmationPopup(false);
    } catch (err) {
      toast.error(`${MessageConstants.DELETE_FAILED} ${startCase(type)}`);
    }
  }


  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file, index) => {
            const { key, name, size, preview } = getFileData(file, index);

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-flex",
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  {type === "image" && (
                    <Image
                      alt="preview"
                      src={preview || get(file, "URL")}
                      ratio="1/1"
                    />
                  )}
                  {type === "pdf" && <PDFpreview name={name} />}
                  {type === "Terms and Conditions" && (
                    <PDFpreview name={name} />
                  )}
                  {type === "Cancellation policy" && <PDFpreview name={name} />}

                  {onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => handleOnDelete(file)}
                      sx={{
                        top: 6,
                        p: "2px",
                        right: 6,
                        position: "absolute",
                        color: "common.white",
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[900], 0.72),
                        "&:hover": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <Iconify icon={"eva:close-fill"} />
                    </IconButton>
                  )}
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify
                  icon={"eva:file-fill"}
                  sx={{ width: 28, height: 28, color: "text.secondary", mr: 2 }}
                />

                <ListItemText
                  primary={typeof file === "string" ? file : name}
                  secondary={typeof file === "string" ? "" : fData(size || 0)}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />

                {onRemove && (
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => onRemove(file)}
                  >
                    <Iconify icon={"eva:close-fill"} />
                  </IconButton>
                )}
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>
      <ConfirmationPopup
        open={isDeleteConfirmtionPopup}
        title={`Do you want to delete the selected ${type}?`}
        handleClose={handleCancelDelete}
        handleConfirm={handleConfirmDelete}
      />
    </>
  );
}
