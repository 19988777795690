export default {
  PACKAGE_STATUS_CHECK_FAILED: "Failed to check package status",
  TEAM_STATUS_CHECK_FAILED: "Failed to check team status",
  PACKAGE_BOOKED: "Package is booked.",
  TEAM_BOOKED: "Team is booked.",
  PACKAGE_LIMIT: "Minimum one package is required",
  ENTER_GST_NUMBER: "Enter GST number",
  ENTER_GST_PERCENT: "Enter GST percent",
  GST_PERCENT_LIMIT: "GST percent should be less than or equal to 100",
  FAILED_TO_DELETE_TEAM: "Failed to delete team!",
  TEAM_DELETED: "Team deleted successfully!",
  DELETED_SUCCESSFULLY: "deleted successfully!",
  DELETE_FAILED: "Failed to delete",
  EDITED_DATA_SENT_SUCCESSFULLY: "Edited data sent for approval!",
  FAILED_TO_SEND_EDITED_DATA: "Failed to send edited data!",
  FAILED_TO_FETCH_FAILED_PROFILE_EDIT_DETAILS:
    "Failed to fetch profile edit details!",
  FAILED_TO_ACKNOWLEDGE_PROFILE_EDIT_REJECTION_REASON:
    "Failed to acknowledge profile edit rejection reason!",
  FAILED_TO_FETCH_VENDOR_PACKAGES: "Failed to fetch vendor packages!",
  FAILED_TO_FETCH_VENDOR_DETAILS: "Failed to fetch vendor details!",
  FAILED_TO_FETCH_USER_DETAILS: "Failed to fetch user details!",
  FAILED_TO_FETCH_VENDOR_IMAGES: "Failed to fetch vendor images!",
  FAILED_TO_FETCH_VENDOR_VIDEOS: "Failed to fetch vendor videos!",
  FAILED_TO_FETCH_USER_REVIEWS: "Failed to fetch user reviews!",
  ENTER_REJECTION_REASON: "Enter reason for rejection!",
  YOUTUBE_LINK_CANNOT_BE_EMPTY: "Youtube link cannot be empty!",
  YOUTUBE_LINK_ALREADY_EXISTS: "Youtube link already exists!",
};
