import { AiOutlineFilePdf } from "react-icons/ai";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { position } from "stylis";

const PDFpreview = ({ name }) => {
  const trimFileName = (filename) => {
    if (!filename) return "";
    if (filename.length <= 10) return filename;
    const start = filename.slice(0, 3);
    const middle = "...";
    const end = filename.substr(filename.length - 6);
    return `${start}${middle}${end}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <AiOutlineFilePdf style={{ color: "red", marginLeft: 20 }} size={30} />
      <Typography
        sx={{
          position: "absolute",
          bottom: -25,
          width: "100%",
          fontSize: "12px",
          left: 8,
        }}
      >
        {trimFileName(name)}
      </Typography>
    </Box>
  );
};

export default PDFpreview;
