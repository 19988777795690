import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";
import RouteConstants from "../constants/RouteConstants";
import get from "lodash/get";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../routes/paths";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, vendor, logout } = useAuth();

  const vendorStatus = get(vendor, "status");

  const navigate = useNavigate();

  console.log("isAuthenticated", isAuthenticated);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Navigate to={`${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`} />
  );
}
// const { pathname } = useLocation();

// const [requestedLocation, setRequestedLocation] = useState(null);

// if (!isInitialized) {
//   return <LoadingScreen />;
// }

// if (!isAuthenticated) {
//   if (pathname !== requestedLocation) {
//     setRequestedLocation(pathname);
//   }
//   return <Login />;
// }

// if (requestedLocation && pathname !== requestedLocation) {
//   setRequestedLocation(null);
//   return <Navigate to={requestedLocation} />;
// }

// return <>{children}</>;
