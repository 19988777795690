// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT_AUTH: "auth",
  LOGIN: "login",
  EDIT: "edit",
  REGISTER: "register",
  ROOT_APP: "app",
  MESSAGES: "messages",
  BOOKING_CONFIRMATION: "bookingConfirmation",
  COMPLAINTS: "complaints",
  BOOKING_CHART: "bookingChart",
  VENDORUSERCHAT: "vendoruserchat",
  DASHBOARD: "dashboard",
  VENDORADMINCHAT: "vendoradminchat",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  IMAGES_AND_VIDEOS: "imagesAndVideos",
  USER_REVIEWS: "userReviews"
};
