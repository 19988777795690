import get from "lodash/get";
import ObjectStorage from "./ObjectStorageService";

const AuthService = {
  isLoggedIn() {
    const data = ObjectStorage.getItem("accessToken", {});
    return !!data;
  },
  getToken() {
    const data = ObjectStorage.getItem("accessToken", {});
    return data;
  },
  getUserName() {
    const data = ObjectStorage.getItem("UserData", {});
    return get(data, "vendorname");
  },
  getUserDetails() {
    const data = ObjectStorage.getItem("UserData", {});
    return data;
  },
  getUserId() {
    const data = ObjectStorage.getItem("UserData", {});
    return get(data, "id", "");
  },
  logout() {
    ObjectStorage.removeItem("accessToken");
    ObjectStorage.removeItem("UserData");
    window.location.reload()
  },
};

export default AuthService;
