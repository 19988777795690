import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import get from "lodash/get";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import ReCAPTCHA from "react-google-recaptcha";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

import { PATH_DASHBOARD, PATH_AUTH } from "../../../routes/paths";
import AuthService from "../../../services/AuthService";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const recaptchaRef = useRef();
  const isMountedRef = useIsMountedRef();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await login({
        email: data.email,
        password: data.password,
      });
      navigate(PATH_DASHBOARD.Dashboard, {
        replace: true,
      });
    } catch (error) {
      console.error(error);
      // reset();
      if (isMountedRef.current) {
        setError("afterSubmit", {
          ...error,
          message: error.message || "Something went wrong",
        });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">
            {get(
              errors,
              "afterSubmit.0.ErrDesc",
              get(errors, "afterSubmit.message")
            )}
          </Alert>
        )}

        <RHFTextField name="email" label="Email Address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
